import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';

const Detail = ({ t, classNameForBG }) => (
  <div className={classNameForBG}>
    <div className="container" id="anchor1">
      <div className="row">
        <div className="col-15 mb-4 mb-lg-0 col-lg-7">
          <div className="media-container">
            <Image
              src="/image/why-report-is-important.png"
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>

        <div className="col-15 col-lg-7 offset-lg-1">
          <h2 className="section__header__title section__header__title--mb-20">{t('whyExpertReportIsImportant')}</h2>
          <p className="section__header__description">{t('whyExpertReportIsImportantExplanation')}</p>
          <div className="row mt-5">
            <div className="col-15 col-sm-5 text-center text-sm-left">
              <h3 className="h2 h2--lighter">{t('seffafAcikArttirma')}</h3>
              <p className="lighter fs-20 fw-100">{`(${t('auction')})`}</p>
            </div>
            <div className="col-15 col-sm-5 text-center text-sm-left">
              <h3 className="h2 h2--lighter">{t('güvenilirEkspertizRaporu')}</h3>
              <p className="lighter fs-20 fw-100">{`(${t('expertReport')})`}</p>
            </div>
            <div className="col-15 col-sm-5 text-center text-sm-left">
              <h3 className="h2 h2--lighter">{t('professional')}</h3>
              <p className="lighter fs-20 fw-100">{`(${t('expertTeam')})`}</p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-15 col-sm-auto col-lg-9 text-center text-sm-left">
              <Link href="/machinery">
                <a className="im-button im-button--primary im-button--mobile-fluid im-button--mobile-center">
                  <span className="fw-900">{t('viewMachinesForSale')}</span>
                  <i className="far fa-long-arrow-right im-button__icon--right" />
                </a>
              </Link>
            </div>
            {/* TODO Örnek rapor hazır olduğunda yorumdan çıkarılıp link verilecek */}
            {/* <div className="col-15 col-sm-6 col-lg-6 text-center text-sm-left mt-3 mt-sm-0">
              <a className="im-button im-button--outline-gray im-button--less-gutter im-button--mobile-fluid im-button--mobile-center">
                <span className="fw-900">{t('reviewSampleReport')}</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Detail.propTypes = {
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(Detail);
